import Vue from 'vue'
import Vuex from 'vuex'
import Vuelidate from 'vuelidate'
import LoggerPlugin, { LoggerFactory } from '@/plugins/logger'
import ApiPlugin from '@/plugins/api'
import Newrelic from '@/plugins/newrelic'
import AppConfigPlugin from '@/plugins/appConfig'
import DonationSpecPlugin from '@/plugins/donationSpec'
import Tracking from '@/plugins/gtm/trackingGtm'
import TrackingAdobeSC from '@/plugins/trackingAdobeSC'
import TrackingMonetate from '@/plugins/trackingMonetate'
import ProgressLoaderMixin from '@/mixins/ProgressLoaderMixin'
import ErrorHandler from '@/helpers/ErrorHandler'
import StopWatchPlugin, { initializeStopWatch } from '@/plugins/stopWatch'

export default function (configuration, store, router) {
  LoggerFactory.setConfigSource(configuration)
  const logger = LoggerFactory.getInstance()

  Vue.use(StopWatchPlugin, initializeStopWatch())
  Vue.mixin(ProgressLoaderMixin)
  Vue.use(Vuex)
  Vue.use(Vuelidate)
  Vue.use(LoggerPlugin, logger)
  Vue.use(AppConfigPlugin, configuration)
  Vue.use(Tracking, { configuration, router, store })
  Vue.use(TrackingAdobeSC, { router, store, logger, pageTemplateVersion: '2020 Donate Template Page' })
  Vue.use(TrackingMonetate, { router, store })
  Vue.use(Newrelic, { store })
  const { appConfig, donationSpec, urlParams } = configuration
  Vue.use(ApiPlugin, { appConfig })
  Vue.use(DonationSpecPlugin, { donationSpec, urlParams, store })
  const { $api, $donationSpec, $configuration, $logger, $gtm, $sc, $monetate } = Vue.prototype
  Object.assign(store, {
    $api, $configuration, $donationSpec, $urlParams: urlParams, $logger, $gtm, $sc, $monetate
  })
  Vue.config.productionTip = false
  const errorHandler = new ErrorHandler(store, router, $logger, $donationSpec)
  Vue.config.errorHandler = errorHandler.handleError
  Vue.config.warnHandler = errorHandler.handleWarning
}
