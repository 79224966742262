import { assert } from '@/helpers'
import { cookies } from '@/configuration'

const landingPageSCConfig = {
  pageName: 'Start', events: 'event37'
}

export const paymentOptionSCConfig = {
  pageName: 'Payment', events: 'event151', prop9: 'ecom'
}

export const paymentWalletOptionSCConfig = {
  ...paymentOptionSCConfig, prop9: 'ecom-wallet'
}

export const paymentCreditCardSCConfig = {
  pageName: 'CreditCard', events: 'event152'
}

export const paymentWalletCreditCardSCConfig = {
  ...paymentCreditCardSCConfig, prop9: 'ecom-wallet'
}

export const paymentPayPalSCConfig = {
  pageName: 'PayPalConfirm', events: 'event153'
}

export const paymentApplePaySCConfig = {
  pageName: 'ApplePayConfirm', events: 'event157'
}

export const paymentAmazonPaySCConfig = {
  pageName: 'AmazonPayConfirm', events: 'event154'
}
export const paymentBankingSCConfig = {
  pageName: 'BankAccount', events: 'event155'
}

export const confirmationPageSCConfig = {
  pageName: 'Confirmation'
}

export const signInPageSCConfig = {
  pageName: 'MemberSignIn'
}

export const signUpPageSCConfig = {
  pageName: 'MemberSignUp', events: 'event76'
}

export const trackSCPage = ({ pageName, events, prop9, eVar97 } = {}) => ({ sc }) => {
  sc.trackView({ pageName, events, prop9, eVar97 })
}

export const trackSCConfirmationPage = ({ pageName }) => ({ store, sc }) => {
  const transactionTotal = store.getters['order/total']
  const paymentType = store.getters['order/paymentType']
  assert(transactionTotal > 0, 'order total amount cannot be zero ')

  if (cookies.userInfo.tier === 'EMAIL') {
    sc.trackView({ pageName, events: 'event77,event48,event49=' + transactionTotal, eVar97: paymentType })
  } else {
    sc.trackView({ pageName, events: 'event48,event49=' + transactionTotal, eVar97: paymentType })
  }
}

export const trackLandingSC = (store, sc) => trackSCPage({ ...landingPageSCConfig })({ store, sc })
export const trackPaymentOptionSC = (store, sc) => trackSCPage(paymentOptionSCConfig)({ store, sc })
export const trackPaymentWalletOptionSC = (store, sc) => trackSCPage(paymentWalletOptionSCConfig)({ store, sc })

export const trackPaymentCreditCardSC = trackSCPage(paymentCreditCardSCConfig)
export const trackPaymentWalletCreditCardSC = trackSCPage(paymentWalletCreditCardSCConfig)
export const trackPaymentBankingSC = trackSCPage(paymentBankingSCConfig)
export const trackPaymentAmazonPaySC = trackSCPage(paymentAmazonPaySCConfig)
export const trackPayPalViewSC = trackSCPage(paymentPayPalSCConfig)
export const trackApplePayViewSC = trackSCPage(paymentApplePaySCConfig)
export const trackConfirmationSC = trackSCConfirmationPage(confirmationPageSCConfig)
export const trackSignInSC = trackSCPage(signInPageSCConfig)
export const trackSignUpSC = trackSCPage(signUpPageSCConfig)
