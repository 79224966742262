import { convertSkuCodeToPageType } from '@/helpers/skuUtils'
import { assert } from '@/helpers'
import { DonationFlowGtmGA4EcommerceOptionsBuilder } from '@/plugins/gtm/builder'

const landingPageBeacon = {
  orderStep: 'Form'
}

export const paymentOptionBeacon = {
  orderStep: 'Payment', isBeginCheckoutPage: true
}

export const paymentCCBeacon = {
  orderStep: 'Payment-CC', isPaymentPage: true
}

export const paymentWalletCCBeacon = {
  ...paymentCCBeacon, isPaymentPage: true
}

export const paymentPaypalBeacon = {
  orderStep: 'Payment-PayPal', isPaymentPage: true
}

export const paymentApplePayBeacon = {
  orderStep: 'Payment-ApplePay', isPaymentPage: true
}

export const paymentBankingBeacon = {
  orderStep: 'Payment-Banking', isPaymentPage: true
}

export const completionBeacon = {
  orderStep: 'Completion'
}

export const signInBeacon = {
  orderStep: 'Sign-In'
}

export const signUpBeacon = {
  orderStep: 'Create-Account'
}

export const trackGTMPage = ({ orderStep, skuCode, isBeginCheckoutPage, isPaymentPage } = {}) => ({ store, gtm }) => {
  const pageType = convertSkuCodeToPageType(skuCode, store)
  const gtmOptions = { pageType, orderStep }
  gtm.trackView(gtmOptions)

  const donationItems = store.getters['cart/donationItems']
  const total = {
    amount: store.getters['cart/cartTotalAmount'],
    currencyCode: store.getters['cart/currencyCode']
  }
  if (isBeginCheckoutPage) {
    const gtmGA4Options = new DonationFlowGtmGA4EcommerceOptionsBuilder()
      .withBeginCheckoutEvent()
      .withCartEcommerceInfo(total, donationItems)
      .build()

    // Track GA4 event with all other non-conflicting options.
    gtm.trackView({ ...gtmOptions, ...gtmGA4Options })
  }

  if (isPaymentPage) {
    const gtmGA4Options = new DonationFlowGtmGA4EcommerceOptionsBuilder()
      .withAddPaymentInfoEvent()
      .withCartEcommerceInfo(total, donationItems)
      .build()

    // Track GA4 event with all other non-conflicting options.
    gtm.trackView({ ...gtmOptions, ...gtmGA4Options })
  }
}

export const trackGTMSignInPage = ({ orderStep } = {}) => ({ gtm }) => {
  gtm.trackView({ pageType: '', orderStep })
}

export const trackGTMConfirmationPage = ({ orderStep, skuCode }) => ({ store, gtm }) => {
  const pageType = convertSkuCodeToPageType(skuCode, store)
  const transactionTotal = store.getters['order/total']
  assert(transactionTotal > 0, 'order total amount cannot be zero ')
  const gtmOptions = { pageType, orderStep, transactionTotal }
  gtm.trackView(gtmOptions)

  const orderNumber = store.getters['order/orderId']
  const donationItems = store.getters['order/donationItems']
  const orderTotal = {
    currencyCode: store.getters['order/currency'],
    amount: store.getters['order/total']
  }
  const paymentType = store.getters['order/financialInstrumentType']

  const gtmGA4Options = new DonationFlowGtmGA4EcommerceOptionsBuilder()
    .withPurchaseEvent()
    .withPurchaseEcommerceInfo(orderNumber, paymentType, orderTotal, donationItems)
    .build()

  // Track GA4 event with all other non-conflicting options.
  gtm.trackView({ ...gtmOptions, ...gtmGA4Options })
}

export const trackLandingGtm = (skuCode, store, gtm) => trackGTMPage({ ...landingPageBeacon, skuCode })({ store, gtm })
export const trackPaymentOptionGtm = trackGTMPage(paymentOptionBeacon)
export const trackPaymentCCGtm = trackGTMPage(paymentCCBeacon)
export const trackPaymentWalletCCGtm = trackGTMPage(paymentWalletCCBeacon)
export const trackPaymentBankingGtm = trackGTMPage(paymentBankingBeacon)
export const trackPayPalViewGtm = trackGTMPage(paymentPaypalBeacon)
export const trackApplePayViewGtm = trackGTMPage(paymentApplePayBeacon)
export const trackCompletionGtm = trackGTMConfirmationPage(completionBeacon)
export const trackSignInGtm = trackGTMSignInPage(signInBeacon)
export const trackSignUpGtm = trackGTMPage(signUpBeacon)
