import Vuex from 'vuex'
import Vue from 'vue'
import auth from '@/store/auth'
import cart from '@/store/cart'
import creditCard from '@/store/creditCard'
import paypal from '@/store/paypal'
import amazonPay from '@/store/amazonPay'
import applePay from '@/store/applePay'
import ach from '@/store/ach'
import order from '@/store/order'
import content from '@/store/content'
import geographies from '@/store/geographies'
import progressLoader from '@/store/progressLoader'
import wallet from '@/store/wallet'

Vue.use(Vuex)

export const modules = {
  cart,
  auth,
  ach,
  geographies,
  creditCard,
  paypal,
  applePay,
  amazonPay,
  order,
  content,
  progressLoader,
  wallet
}

export const initialState = {
  cart: cart.state(),
  auth: auth.state(),
  ach: ach.state(),
  geographies: geographies.state(),
  creditCard: creditCard.state(),
  paypal: paypal.state(),
  amazonPay: amazonPay.state(),
  applePay: applePay.state(),
  order: order.state(),
  content: content.state(),
  progressLoader: progressLoader.state(),
  wallet: wallet.state()
}

export const storeConfig = {
  modules,
  mutations: {
    // This is global mutation to reset the store state
    reset (state) {
      Object.keys(state).forEach(key => {
        const copyOfInitialState = JSON.parse(JSON.stringify(initialState[key]))
        Object.assign(state[key], copyOfInitialState)
      })
    }
  }
}

const store = new Vuex.Store(storeConfig)

export default store
