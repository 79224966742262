import Vue from 'vue'
import bootstrap from '@/apps/default-2020/bootstrap'
import configuration from '@/configuration'
import initRouter from '@/apps/default-2020/router'
import App from '@/apps/default-2020/App'
import store from '@/apps/default-2020/store'
import 'normalize.css/normalize.css'
import '@/assets/scss/default-2020/main.scss'

const router = initRouter(configuration)
bootstrap(configuration, store, router)

const app = new Vue({
  router,
  provide: function () {
    return {
      donationSpec: this.$donationSpec
    }
  },
  store,
  render: h => h(App)
})
app.$mount('#app')
window.top.$app = app
