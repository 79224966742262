import {
  requiresAuthentication,
  requiresCart,
  requiresOrder,
  requiresSelectedWalletPaymentMethod
} from '@/router/guards'
import {
  trackPaymentOptionGtm,
  trackPaymentCCGtm,
  trackPaymentBankingGtm,
  trackCompletionGtm,
  trackPayPalViewGtm,
  trackApplePayViewGtm,
  trackPaymentWalletCCGtm
} from '@/router/trackingGtm'

import {
  trackPaymentCreditCardSC,
  trackPaymentBankingSC,
  trackPayPalViewSC,
  trackPaymentAmazonPaySC,
  trackConfirmationSC,
  trackApplePayViewSC,
  trackPaymentWalletCreditCardSC
}
  from '@/router/trackingAdobeSC'

import {
  trackMnPaymentOption,
  trackMnPaymentCreditCard,
  trackMnPaymentBanking,
  trackMnPaymentAmazonPay,
  trackMnPayPalView,
  trackMnConfirmation,
  trackMnApplePayView,
  trackMnPaymentWalletCreditCard

} from '@/router/trackingMonetate'

import store from '@/apps/default-2020/store'

const IndexView = () => import(/* webpackChunkName: "indexView" */ '@/apps/default-2020/views/_id/default-2020/IndexView')
const PaymentOptionsView = () => import(/* webpackChunkName: "paymentOptionsView" */ '@/apps/default-2020/views/_id/default-2020/payment/PaymentOptionsView')
const PaymentCreditCardView = () => import(/* webpackChunkName: "paymentCreditCardView" */ '@/apps/default-2020/views/_id/default-2020/payment/PaymentCreditCardView')
const PaymentCreditCardFromWalletView = () => import(/* webpackChunkName: "paymentCreditCardFromWalletView" */ '@/apps/default-2020/views/_id/default-2020/payment/wallet/PaymentCreditCardFromWalletView')
const PaymentBankingView = () => import(/* webpackChunkName: "paymentBankingView" */ '@/apps/default-2020/views/_id/default-2020/payment/PaymentBankingView')
const PaymentAmazonPayView = () => import(/* webpackChunkName: "paymentAmazonPayView" */ '@/apps/default-2020/views/_id/default-2020/payment/PaymentAmazonPayView')
const PayPalReviewView = () => import(/* webpackChunkName: "payPalReviewView" */ '@/apps/default-2020/views/_id/default-2020/payment/PayPalReviewView')
const ApplePayReviewView = () => import(/* webpackChunkName: "applePayReviewView" */ '@/apps/default-2020/views/_id/default-2020/payment/ApplePayReviewView')
const ConfirmationView = () => import(/* webpackChunkName: "confirmationView" */ '@/apps/default-2020/views/_id/default-2020/ConfirmationView')

export const beforeEnterIndex = function (store) {
  return function (to, from, next) {
    const { $urlParams, $donationSpec } = store
    const metadata = $donationSpec.metadata
    const { templateName, id } = metadata
    // Handle session expired.
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('sessionExpired')) {
      store.commit('auth/sessionExpiredFlag', true)
    }
    next({ path: `/donation/${id}/${templateName}`, query: $urlParams.queryParams })
  }
}

export const beforeEnterRoutes = function (store) {
  return function (to, from, next) {
    store.commit('progressLoader/resetState')
    const route = to.matched.find(record => record.meta.guards)
    if (route && !route.meta.guards.every(guard => guard(store))) {
      next({ name: indexRoute.name, params: { id: store.$donationSpec.id } })
    } else {
      next()
    }
  }
}

export const indexRoute = {
  path: '/donation/:id',
  name: 'index',
  beforeEnter: beforeEnterIndex(store)
}

export const default2020 = {
  path: '/donation/:id/default-2020',
  name: 'default-2020',
  component: IndexView
}

export const paymentOptionRoute = {
  path: '/donation/:id/default-2020/payment',
  name: 'default-2020-payment',
  component: PaymentOptionsView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingGtm: [trackPaymentOptionGtm],
    trackingMonetate: [trackMnPaymentOption]
  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paymentCCRoute = {
  path: '/donation/:id/default-2020/payment/credit-card',
  name: 'default-2020-credit-card',
  component: PaymentCreditCardView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingGtm: [trackPaymentCCGtm],
    trackingAdobeSC: [trackPaymentCreditCardSC],
    trackingMonetate: [trackMnPaymentCreditCard]

  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paymentCCFromWalletRoute = {
  path: '/donation/:id/default-2020/payment/wallet/credit-card',
  name: 'default-2020-wallet-credit-card',
  component: PaymentCreditCardFromWalletView,
  meta: {
    guards: [requiresAuthentication, requiresCart, requiresSelectedWalletPaymentMethod],
    trackingGtm: [trackPaymentWalletCCGtm],
    trackingAdobeSC: [trackPaymentWalletCreditCardSC],
    trackingMonetate: [trackMnPaymentWalletCreditCard]

  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paymentAmazonRoute = {
  path: '/donation/:id/default-2020/payment/amazon-pay',
  name: 'default-2020-amazon-pay',
  component: PaymentAmazonPayView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingAdobeSC: [trackPaymentAmazonPaySC],
    trackingMonetate: [trackMnPaymentAmazonPay]
  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paymentBankingRoute = {
  path: '/donation/:id/default-2020/payment/banking',
  name: 'default-2020-banking',
  component: PaymentBankingView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingGtm: [trackPaymentBankingGtm],
    trackingAdobeSC: [trackPaymentBankingSC],
    trackingMonetate: [trackMnPaymentBanking]
  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paypalReviewRoute = {
  path: '/donation/:id/default-2020/payment/paypal-review',
  name: 'default-2020-paypal-review',
  component: PayPalReviewView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingGtm: [trackPayPalViewGtm],
    trackingAdobeSC: [trackPayPalViewSC],
    trackingMonetate: [trackMnPayPalView]

  },
  beforeEnter: beforeEnterRoutes(store)
}

export const applePayReviewRoute = {
  path: '/donation/:id/default-2020/payment/applepay-review',
  name: 'default-2020-apple-pay-review',
  component: ApplePayReviewView,
  meta: {
    guards: [requiresAuthentication, requiresCart],
    trackingGtm: [trackApplePayViewGtm],
    trackingAdobeSC: [trackApplePayViewSC],
    trackingMonetate: [trackMnApplePayView]

  },
  beforeEnter: beforeEnterRoutes(store)
}

export const paymentConfirmationRoute = {
  path: '/donation/:id/default-2020/confirmation',
  name: 'default-2020-confirmation',
  component: ConfirmationView,
  meta: {
    guards: [requiresAuthentication, requiresOrder],
    trackingGtm: [trackCompletionGtm],
    trackingAdobeSC: [trackConfirmationSC],
    trackingMonetate: [trackMnConfirmation]
  },
  beforeEnter: beforeEnterRoutes(store)
}

export const httpsRoute = {
  path: '/http*',
  beforeEnter: (to, from, next) => {
    window.location.href = to.fullPath.substring(1)
  }
}

export const oopsRoute = {
  path: '/oops',
  name: 'notfound',
  // route level code-splitting
  // this generates a separate chunk (notfound.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => {
    // eslint-disable-next-line no-unused-expressions
    import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  }
}

export const routes = [indexRoute, default2020, paymentOptionRoute, paymentCCRoute, paymentCCFromWalletRoute,
  paymentAmazonRoute, paymentBankingRoute, paypalReviewRoute, applePayReviewRoute, paymentConfirmationRoute, httpsRoute, oopsRoute]
