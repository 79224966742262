export const requiresAuthentication = (store) => {
  // This method might throw an error in case of expired session
  return store.getters['auth/isAuthenticated']
}

export const requiresCart = (store) => {
  return !store.getters['cart/isEmpty']
}

export const requiresOrder = (store) => {
  return !store.getters['order/isEmpty']
}

export const requiresSelectedWalletPaymentMethod = (store) => {
  return store.getters['wallet/selectedPaymentMethod']
}

export const clearFlowState = (store) => {
  // This is special guard for confirmation page to clear some vuex store modules
  // beforeRouteEnter Confirmation view
  // First of all we should clear cart information since after cart checkout it is not more relevant.
  store.commit('cart/resetState')
  // Also we should reset wallet payment options. Since after checkout we might have new wallet option.
  store.commit('wallet/resetState')
  // Also we should update auth sessionType. Since after checkout our sessionType might be updated due cookie change.
  if (store.getters['auth/isAnonymous']) {
    store.commit('auth/sessionType')
  }
  return true
}
