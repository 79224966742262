import Router from 'vue-router'
import { routes } from './routes'

const initRouter = (configuration) => {
  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  // This router.push method override serves for only one purpose. Is to preserve query between calls.
  const queryToPreserve = Object.assign({}, configuration.urlParams.queryParams)
  router.push = async function (location) {
    if (typeof location !== 'string') {
      location.query = Object.assign({}, queryToPreserve, location.query)
    }
    await Router.prototype.push.call(this, location)
  }

  return router
}

export default initRouter
