import { ATTRIBUTION_CODE, CAMPAIGN_URL_MAPPING, KEY_CODE_URL_MAPPING, PROCESSING_FEE_ENABLED } from '@/store/constants'
import cookie from 'js-cookie'
import { mapObjectProperties, reduceQuery, uuidv4 } from '@/helpers'
import { currentWindow } from '@/plugins/windows'
const appConfig = Object.freeze(currentWindow.appConfig ?? {})
const donationSpec = Object.freeze(currentWindow.templateData)

export const DEVICE_TYPE = {
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  DESKTOP: 'Desktop'
}

export const browserPlatform = {
  get deviceType () {
    return getDeviceType()
  }
}

/**
 * Returns the device type for the current viewport size.
 * @returns {string}
 */
export const getDeviceType = () => {
  const viewportWidth = currentWindow.innerWidth

  if (viewportWidth < 768) {
    return DEVICE_TYPE.MOBILE
  }
  if (viewportWidth < 1200) {
    return DEVICE_TYPE.TABLET
  }
  return DEVICE_TYPE.DESKTOP
}

export const COOKIE_USER_TOKEN = 'userToken'
export const COOKIE_HASH = 'hash'
export const COOKIE_FE_SESSION = 'feSessionToken'
export const COOKIE_CR_DONATION = 'crDonation'
export const COOKIE_CSR_USER_TOKEN = 'csrUserToken'
export const COOKIE_CSR_IMPERSONATED_GUID = 'csrImpersonatedUserGuid'

export const cookies = {
  get authToken () {
    return cookie.get(COOKIE_CSR_USER_TOKEN) || cookie.get(COOKIE_USER_TOKEN) || cookie.get(COOKIE_HASH)
  },

  get csrImpersonatedUserGuid () {
    return cookie.get(COOKIE_CSR_IMPERSONATED_GUID)
  },

  get csrUserToken () {
    return cookie.get(COOKIE_CSR_USER_TOKEN)
  },

  get userInfo () {
    return reduceQuery(cookie.get('userInfo'))
  },
  get feSessionToken () {
    return cookie.get(COOKIE_FE_SESSION)
  },
  initFeSessionToken () {
    const uuid = uuidv4()
    cookie.set(COOKIE_FE_SESSION, uuid, { secure: true })
    return uuid
  },
  get memberType () {
    const userInfo = this.userInfo
    const userInfoId = userInfo.ID
    const productList = userInfo.products
    if (userInfoId && productList) {
      return 'Subscriber'
    }
    return 'Visitor'
  },
  deleteCookie (name, path, domain) {
    window.document.cookie = `${name}=${path ? `;path=${path}` : ''}${domain ? `;domain=${domain}` : ''};expires=Thu, 01 Jan 1970 00:00:01 GMT`
  },
  // eslint-disable-next-line accessor-pairs
  set crDonation (objectValue) {
    // Custom encoder to fulfil requirements. Instead of https://www.rfc-editor.org/rfc/rfc6265
    const customEncodedCookie = cookie.withConverter({
      write: function (value) {
        return encodeURIComponent(value)
      }
    })
    if (objectValue) {
      const {
        amount,
        selectedContributionIndex,
        selectedFrequencyType,
        donationSpecId
      } = objectValue
      const stringValue = `da=${amount}&sf=${selectedContributionIndex + 1}&pi=${donationSpecId}&ft=${selectedFrequencyType}`
      customEncodedCookie.set(COOKIE_CR_DONATION, stringValue, {
        expires: 7, // 7 days
        path: '/',
        sameSite: 'none',
        secure: true,
        domain: appConfig.domain
      })
    } else {
      customEncodedCookie.remove(COOKIE_CR_DONATION, {
        path: '/', domain: appConfig.domain
      })
    }
  }
}

const queryParams = reduceQuery(currentWindow.location.search)

export const urlParams = {
  get queryParams () {
    if (queryParams.sessionExpired) {
      delete queryParams.sessionExpired
    }
    if (queryParams.error) {
      delete queryParams.error
    }
    return queryParams
  },
  get campaignCodes () {
    return mapObjectProperties(queryParams, CAMPAIGN_URL_MAPPING)
  },
  get hasCampaignCodes () {
    return !!(this.campaignCodes.campaignKey && this.campaignCodes.externalReferenceCode)
  },
  get keyCodes () {
    return mapObjectProperties(queryParams, KEY_CODE_URL_MAPPING)
  },
  get sessionExpired () {
    return queryParams.sessionExpired
  },
  get isProcessingFeeEnabled () {
    return !!queryParams[PROCESSING_FEE_ENABLED]
  },
  get attributionCode () {
    return queryParams[ATTRIBUTION_CODE]
  },
  get hasAttributionCode () {
    return !!queryParams[ATTRIBUTION_CODE]
  },
  get testCaptchaSiteKey () {
    const testSiteKey = queryParams['testCaptchaSiteKey']
    if (appConfig.hCaptchaConfiguration.testSiteKeys.includes(testSiteKey)) {
      return testSiteKey
    }
    return undefined
  }
}

export default { appConfig, donationSpec, cookies, urlParams }
