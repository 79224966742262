<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { cookies } from '@/configuration'

const LOGGER_NAME = 'App.vue'
export default {
  name: 'App',
  created () {
    if (!cookies.feSessionToken) {
      cookies.initFeSessionToken()
      const info = 'initialized feSessionToken successfully'
      this.$logger.info(LOGGER_NAME, { info })
    }
  }
}
</script>
<style lang="scss">

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  line-height: 1.4;
  color: $tertiaryBlack;
  font-size: 16px;
}
#app {
  font-family: "Averta", "Avenir", "Avenir Next", Helvetica, Helvetica Neue, Arial, sans-serif;
  font-weight: 300;
  text-align: center;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}
input[type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
button, a {
  cursor: pointer;
}
.don-cr-header-content__header {
  font-size: 24px;
  font-weight: 600;
  color: $tertiaryGray;
}
.don-cr-header-content__subheader {
  font-size: 18px;
  padding-bottom: 20px;
}
</style>
