import { cookies, urlParams, COOKIE_CSR_USER_TOKEN, COOKIE_CSR_IMPERSONATED_GUID } from '@/configuration'
import {
  SESSION_TYPE_ANONYMOUS,
  SESSION_TYPE_REGISTERED,
  WITH_CREDENTIALS_OPTION
} from '@/store/constants'
import { isCsrImpersonation } from '@/helpers/impersonationHelper'
import { isAuthError, isForbiddenError, isUnauthorizedError } from '@/helpers/globalErrorHelper'

const LOGGER_NAME = 'auth.js'

export function toAuthCustomerAddress (customerAddress) {
  const {
    city,
    country,
    extStreet: address2,
    state,
    streetAddress: address,
    zipCode: zip
  } = customerAddress
  return {
    city, country, address2, address, state, zip
  }
}

export function toAuthCustomer (response) {
  const { data } = response
  const { address: customerAddress, ...customer } = data
  if (customerAddress) {
    customer.address = toAuthCustomerAddress(customerAddress)
  }
  return customer
}

export function toCustomerProps (customer) {
  const { firstName, lastName, email } = customer
  return { firstName, lastName, email }
}

export const userInfoFn = () => ({
  firstName: '',
  lastName: '',
  email: '',
  password: ''
})

export const authStateFn = (data = {}) => ({
  isAuthenticated: false,
  sessionExpiredFlag: false,
  donationSpecId: '',
  loginStatus: { status: '' },
  customerId: '',
  sessionToken: '',
  sessionType: '',
  customer: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    address: {
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      zip: ''
    }
  },
  ...data
})

export const actions = {
  async autoLogin ({ state, dispatch }) {
    let authState = state
    try {
      if (urlParams.hasCampaignCodes) {
        authState = await dispatch('campaignLogin', urlParams.campaignCodes)
      } else if (cookies.authToken && cookies.userInfo?.ID) {
        authState = await dispatch('cookieLogin', { cookies })
      }
    } catch (e) {
      this.$logger.error(LOGGER_NAME, { error: e, info: 'autoLogin failed' })
      await dispatch('logout')
    }
    return { ...authState }
  },
  async campaignLogin ({ dispatch, state, commit }, { externalReferenceCode, campaignKey }) {
    let userToken = cookies.authToken
    let response
    let anonymousAuth
    const viewCampaignCommand = { externalReferenceCode, campaignKey, restrictCookies: true }
    try {
      if (!userToken) {
        const { data } = await this.$api.authenticationApi.anonymousLogin()
        anonymousAuth = data
        userToken = data.sessionToken
      }
      response = await this.$api.campaignsApi.viewCampaign(userToken, viewCampaignCommand, WITH_CREDENTIALS_OPTION)
    } catch (e) {
      !anonymousAuth && await dispatch('logout')
      if (e.response?.status === 404) {
        return
      }
      if (isAuthError(e)) {
        const { data } = await this.$api.authenticationApi.anonymousLogin()
        anonymousAuth = data
        response = await this.$api.campaignsApi.viewCampaign(anonymousAuth.sessionToken, viewCampaignCommand, WITH_CREDENTIALS_OPTION)
      }
    }
    const { data: { authentication: campaignAuth, customer: customerResponseData, cartId } } = response
    const isCampaignAuthenticated = !!(campaignAuth && campaignAuth.sessionToken)
    const newAuth = isCampaignAuthenticated ? campaignAuth : anonymousAuth
    const sessionType = isCampaignAuthenticated ? SESSION_TYPE_REGISTERED : SESSION_TYPE_ANONYMOUS
    const customer = toAuthCustomer({ data: customerResponseData })
    await dispatch('login', { ...newAuth, customer, sessionType })
    commit('cart/cartId', cartId, { root: true })
    return { ...state, cartId }
  },
  async cookieLogin ({ dispatch, state }, { cookies }) {
    const sessionToken = cookies.authToken
    if (!isCsrImpersonation()) {
      await this.$api.authenticationApi.rememberMeLogin({ sessionToken })
    }
    const userInfo = cookies.userInfo
    const customerId = +userInfo.ID
    const response = await this.$api.customersApi.fetchCustomerById(sessionToken, customerId)
    const customer = toAuthCustomer(response)
    await dispatch('login', { customerId, sessionToken, sessionType: SESSION_TYPE_REGISTERED, customer })
    return state
  },
  async anonymousLogin ({ state, dispatch, commit }, { customer } = {}) {
    const { data } = await this.$api.authenticationApi.anonymousLogin()
    await dispatch('login', { ...data, sessionType: SESSION_TYPE_ANONYMOUS, customer: toCustomerProps(customer) })
    return data
  },
  async registeredLogin ({ dispatch, commit, state }, { userLoginCommand }) {
    try {
      commit('loginStatus', authStateFn().loginStatus)
      const { data } = await this.$api.authenticationApi.registeredLogin(userLoginCommand, undefined, undefined, WITH_CREDENTIALS_OPTION)
      const { customerId, sessionToken } = data
      const response = await this.$api.customersApi.fetchCustomerById(sessionToken, customerId)
      const customer = toAuthCustomer(response)
      await dispatch('login', { ...data, sessionType: SESSION_TYPE_REGISTERED, customer })
      return state
    } catch (e) {
      if (isUnauthorizedError(e)) {
        commit('loginStatus', { status: 401 })
      } else if (isForbiddenError(e)) {
        commit('loginStatus', { status: 403 })
      } else {
        const { response } = e
        if (response) {
          commit('loginStatus', { status: response.status })
        }
      }
      throw e
    }
  },
  login ({ commit, state }, { customer: customerData, ...session }) {
    commit('resetState')
    commit('cart/resetState', null, { root: true })
    commit('login', { ...session, donationSpecId: this.$donationSpec.id })
    const { address, ...customer } = customerData
    commit('customer', customer)
    if (address) {
      commit('address', address)
    }
    return state
  },
  async logout ({ commit, state }, resetState = true) {
    try {
      const domain = this.$configuration.appConfig.domain
      await this.$api.authenticationApi.logout(WITH_CREDENTIALS_OPTION)
      cookies.deleteCookie(COOKIE_CSR_USER_TOKEN, '/', domain)
      cookies.deleteCookie(COOKIE_CSR_IMPERSONATED_GUID, '/', domain)
    } catch (e) {
      this.$logger.error(LOGGER_NAME, { error: e, info: 'logout failed' })
    }
    if (resetState) {
      commit('reset', null, { root: true })
    }
    return state
  },
  readAndResetSessionExpiredFlag ({ commit, getters }) {
    // Read value from store auth/isSessionExpiredFlag to show the message.
    const sessionExpiredFlag = getters['isSessionExpiredFlag']
    // To show session expired only once on IndexView we have to set it to false
    commit('sessionExpiredFlag', false)
    return sessionExpiredFlag
  }
}

export const mutations = {
  login (state, payload) {
    Object.assign(state, { isAuthenticated: true, ...payload, loginStatus: { status: 200 } })
  },
  resetState (state) {
    Object.assign(state, authStateFn())
  },
  auth (state, payload) {
    Object.assign(state, payload)
  },
  sessionType (state) {
    const userToken = cookies.authToken
    state.sessionType = userToken ? SESSION_TYPE_REGISTERED : SESSION_TYPE_ANONYMOUS
  },
  sessionExpiredFlag (state, payload) {
    state.sessionExpiredFlag = payload
  },
  customer (state, payload) {
    Object.assign(state.customer, payload)
  },
  address (state, payload) {
    state.customer.address = Object.assign({}, state.customer.address, payload)
  },
  loginStatus (state, payload) {
    Object.assign(state.loginStatus, payload)
  }
}

export const getters = {
  address ({ customer }) {
    return customer.address
  },
  customer ({ customer }) {
    return toCustomerProps(customer)
  },
  fullCustomer ({ customer }) {
    return customer
  },
  sessionToken (state) {
    return state.sessionToken
  },
  isSessionExpiredFlag (state) {
    return state.sessionExpiredFlag
  },
  isAuthenticated (state) {
    return state.isAuthenticated
  },
  isAnonymous (state) {
    return state.sessionType === SESSION_TYPE_ANONYMOUS
  },
  isLoggedOut (state) {
    return state.sessionType === SESSION_TYPE_REGISTERED && !cookies.userInfo?.ID
  }
}

const module = {
  namespaced: true,
  state: authStateFn,
  actions,
  mutations,
  getters
}

export default module
